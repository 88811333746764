import React from 'react';
import {Typography} from "@mui/material";
import {LessonContext} from "../lessons-context";
import {Alarm} from "@mui/icons-material";
import {styled} from "@mui/system";

const LessonComponent = styled('div')(()=>({
  flex: 1,
  display: 'flex',
  position: 'relative',
  borderBottom: '1px solid #e0e7e8',
  marginBottom: '0.35em',
  '&:last-of-type': {
    marginBottom: '0',
  }
}));

const RoomWrapper = styled('div')(()=>({
  width: '20%',
  flex: 1
}));

const LessonRoom = styled(Typography)(()=>({
  fontFamily: 'CirceGlyphs',
  fontSize: '220%',
  color: '#003049',
  fontWeight: 600
}));

const LessonSubject = styled('span')(()=>({
  textAlign: 'left',
  fontSize: '130%',
  flex: 5,
  color: '#003049',
  lineHeight: '1.25em'
}));

const LessonTime = styled(Typography)(()=>({
  fontFamily: 'CirceGlyphs',
  fontSize: '0.8rem',
  textTransform: 'uppercase',
  backgroundColor: '#c6bfbc',
  color: '#fff',
  letterSpacing: '.5px',
  whiteSpace: 'nowrap',
  padding: '2px 10px',
  marginRight: ".5em",
  borderRadius: '20px',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignContent: 'center',
  alignItems: 'center',
  '&.current' : {
    color: '#fff',
    backgroundColor: '#eb564f'
  }
}));

const LessonTeacher = styled('div')(()=>({
  fontSize: '70%',
  fontWeight: 600,
  marginTop: '1%'
}));

function Lesson(props) {
  const {data, number} = props;
  let {room, subjectFull, subgroup, teacher} = data[0];
  if(room === 'УП') room = 'УПУ';
  if(data.length === 2) {
    let {room: room1, subjectFull: subjectFull1, subgroup: subgroup1, teacher: teacher1} = data[1];
    if(room1 === 'УП') room1 = 'УПУ';
    room = `${room} / ${room1}`;
    subjectFull = `${subjectFull}(${subgroup}) / ${subjectFull1}(${subgroup1})`;
    teacher = `${teacher} / ${teacher1}`;
  }
  return (
  <LessonComponent>
    <RoomWrapper>
      <LessonRoom variant={"h5"} gutterBottom>{room}</LessonRoom>
    </RoomWrapper>
    <LessonSubject>
      <LessonContext.Consumer>
        {lesson => (
            // eslint-disable-next-line eqeqeq
            <LessonTime variant={"body2"} gutterBottom className={`${lesson.number === parseInt(number)?"current":""}`}>
              <Alarm fontSize={'small'} style={{marginRight: '10px'}}/>
              {`${lesson.bells[number].start} - ${lesson.bells[number].end}`}
            </LessonTime>
        )}
      </LessonContext.Consumer>
      <div>{subjectFull}</div>
      <LessonTeacher>
        {teacher}
      </LessonTeacher>
    </LessonSubject>
  </LessonComponent>
  );
}

export default Lesson;