import React from 'react';
import GroupBlock from "./GroupBlock";

function pages(obj, page, step=9) {
  return Object.keys(obj)
      .slice(step*(page-1), step*page)
      .reduce((memo, current) =>{
        memo[current] = obj[current];
        return memo;
      }, {})
}

function CustomGrid(props) {
    const {lessons, page, grid_size_h, grid_size_w } = props;
    const data = pages(lessons, page, grid_size_h*grid_size_w);
    return (
        lessons?Object.values(data).map((lesson, index) => <GroupBlock key={index} data={lesson} grid_size_h = {grid_size_h} grid_size_w = {grid_size_w}/>):null
    );
}

export default CustomGrid;